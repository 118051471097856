<template>
  <div
    v-if="isOpen"
    class="modal-delete swal2-container swal2-center swal-conection swal2-backdrop-show"
  >
    <div
      class="swal2-popup swal2-modal swal2-show"
      style="display: flex"
    >
      <div class="swal2-header position-relative">
        <div class="swal2-title">{{ $t('IntegrationPage.attention') }}</div>
        <button
          type="button"
          class="swal2-close d-flex"
          aria-label="Close this dialog"
          @click="UPDATE_MODAL_DELETE(false)"
        >
          ×
        </button>
      </div>
      <div class="swal2-content">
        <p>
          {{ level === 1 ? $t('DepartmentPage.DeleteText1') : $t('DepartmentPage.SubDeleteText1') }}
          <b>{{ name }}</b
          >.
        </p>
        <p>
          {{ level === 1 ? $t('DepartmentPage.DeleteText2') : $t('DepartmentPage.SubDeleteText2') }}
        </p>
        <b-form-input
          v-model="inputValue"
          :placeholder="$t('ResourceStatus.Placeholder')"
          class="swal2-input"
          @input="(e) => verifyEqualName(e)"
        />
      </div>
      <div class="swal2-actions">
        <button
          type="button"
          class="swal2-cancel btn btn-outline-danger ml-1 d-inline-block"
          @click="UPDATE_MODAL_DELETE(false)"
        >
          {{ $t('IntegrationPage.cancel') }}
        </button>
        <button
          type="button"
          class="swal2-confirm btn btn-primary d-inline-block"
          :disabled="disabled"
          @click="onDelete()"
        >
          {{ $t('IntegrationPage.delete') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { BFormInput } from 'bootstrap-vue';
  import { mapState, mapMutations } from 'vuex';

  export default {
    components: {
      BFormInput
    },
    props: {
      level: {
        type: Number,
        default: null
      },
      name: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        isOpen: false,
        inputValue: '',
        disabled: true
      };
    },
    mounted() {
      this.UPDATE_MODAL_DELETE(false);
    },
    methods: {
      ...mapMutations('departments', ['UPDATE_MODAL_DELETE']),
      verifyEqualName(e) {
        if (e === this.name) {
          this.disabled = false;
        } else {
          this.disabled = true;
        }
      },
      onDelete() {
        this.$emit('confirm');
      }
    },
    computed: {
      ...mapState('departments', {
        isModalDeleteOpen: 'isModalDeleteOpen'
      })
    },
    watch: {
      isModalDeleteOpen(v) {
        this.isOpen = v;
        if (!v) this.inputValue = '';
      }
    }
  };
</script>
<style lang="scss">
  .swal-conection.modal-delete {
    .swal2-modal {
      padding: 0 !important;
      border-radius: 6px;
      width: 710px;

      .swal2-header {
        background-color: #d32f2f;
        padding: 20px 16px;
        border-radius: 6px 6px 0px 0px;
        flex-direction: row;
        justify-content: left;

        .swal2-title {
          color: #ffff;
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          margin: 0;
        }

        .swal2-close {
          border-radius: 6px;
          width: 34px;
          background-color: #ffff;
          height: 34px;
          top: -5px;
          position: absolute;
          right: -5px;
          padding-bottom: 5px;
          padding-left: 2px;
        }
      }

      .swal2-content {
        padding: 20px 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        p {
          text-align: start;
          margin-bottom: 16px;
        }

        .swal2-html-container {
          font-weight: 400;
          text-align: left;
          font-size: 14px;
          line-height: 20px;
          color: #4c4541;
          display: flex !important;
        }
      }

      .swal2-actions {
        padding: 0 16px 20px 16px;
        display: flex;
        justify-content: right;

        .swal2-confirm {
          margin-left: 10px !important;
          order: 2;
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          color: #ffff;
          border-color: #974900 !important;
          background-color: #974900 !important;
          border-radius: 5px;

          &:hover {
            box-shadow: none !important;
          }

          &:disabled {
            color: #7e7570 !important;
            font-size: 17px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.213px;
            background: #ece0db !important;
            border-color: #ece0db !important;
          }
        }

        .swal2-cancel {
          order: 1;
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          color: #974900 !important;
          border-radius: 5px;
          border-color: transparent !important;

          &:hover {
            background-color: #ffede1 !important;
          }
        }
      }
    }
  }
</style>
