<template>
  <div>
    <b-sidebar
      id="department-sidebar-right"
      :class="['sidebar-add-roles', isRoleModalOpen ? '--role-openned' : '--role-closed']"
      right
      bg-variant="white"
      width="407px"
      v-model="isOpen"
      @hidden="closeModal"
    >
      <template #header>
        <div class="header-sidebar d-flex justify-content-between">
          <span class="sidebar-title">
            {{ $t(modalTitle) }}
          </span>

          <span
            class="close-icon"
            @click="closeModal()"
          >
            <CloseIcon />
          </span>
        </div>

        <div class="separator"></div>
      </template>
      <template>
        <b-col md="12">
          <b-form-group
            :label="$t('DepartmentPage.Name')"
            label-for="name"
            :invalid-feedback="
              flagNameEqual
                ? this.modalTitle.includes('Department') || this.modalTitle.includes('Departamento')
                  ? $t('DepartmentPage.DepartmentNameAlreadyExists')
                  : $t('DepartmentPage.SubdepartmentNameAlreadyExists')
                : $t('RequiredField')
            "
          >
            <b-form-input
              id="name"
              v-model="nameInput"
              :placeholder="$t('ResourceStatus.Placeholder')"
              :state="states.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            :label="$t('DepartmentPage.Description')"
            label-for="description"
            :invalid-feedback="$t('RequiredField')"
          >
            <b-form-textarea
              id="description"
              v-model="description"
              :placeholder="$t('ResourceStatus.Placeholder')"
              :state="states.description"
              no-resize
            />
          </b-form-group>
        </b-col>
        <b-col
          md="12"
          class="form-group"
        >
          <label for="responsible-select">
            {{ $t('DepartmentPage.Responsible') }}
          </label>
          <SingleSelect
            id="responsible-select"
            :placeholder="$t('Select')"
            :optionSelected="responsible"
            :options="responsibleList"
            :searchable="false"
            :translate="true"
            :state="states.responsible"
            ref="multiSelectResponsible"
            :multiselect="true"
            class="chip-responsible"
            @input="(value) => (responsible = value)"
          >
            {{ $t('RequiredField') }}
          </SingleSelect>
        </b-col>
        <b-col
          md="12"
          class="form-group roles-form"
        >
          <div class="title">{{ $t('DepartmentPage.Roles') }}</div>
          <div :class="['roles-block', addedRoles.length ? 'has-items' : '']">
            <span
              v-if="!addedRoles.length"
              class="no-roles"
            >
              {{ $t('DepartmentPage.NoRoles') }}
            </span>
            <div
              v-if="forceRenderAddedList"
              class="added-block"
            >
              <div
                v-for="role in addedRoles"
                class="added-roles"
                :key="role.id"
              >
                <div class="role-name">
                  <b-form-input
                    :id="`editRoleName-${role.id}`"
                    :class="role.isEditting ? 'role-edit-input' : ''"
                    :ref="`role-input-${role.id}`"
                    :value="role.name"
                    :state="states.roleName"
                    :readonly="!role.isEditting"
                    @click="currentRoleName = role.name"
                    @input="
                      (value) => {
                        currentRoleName = value;
                        updateRoleName(role.id);
                      }
                    "
                  />
                  <span
                    v-if="hasError && role.isEditting"
                    class="invalid-input"
                    >{{ errorMessage }}</span
                  >
                </div>
                <div
                  class="icons"
                  v-if="!role.isEditting"
                >
                  <div
                    class="icon edit"
                    @click="onEditRole(role.id)"
                  >
                    <EditRole />
                  </div>
                  <div
                    class="icon delete"
                    @click="onRemoveRole(role.id)"
                  >
                    <RemoveRole />
                  </div>
                </div>
                <div
                  class="icons"
                  v-else
                >
                  <div
                    class="icon add"
                    @click="updateRoleName(role.id, true)"
                  >
                    <ConfirmIcon />
                  </div>
                  <div
                    class="icon delete"
                    @click="stopEditting()"
                  >
                    <DeclineIcon />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="add-role"
              @click="openRoles()"
            >
              <AddRole />
              <span>{{ $t('DepartmentPage.AddRole') }}</span>
            </div>
          </div>
        </b-col>
      </template>

      <template #footer>
        <b-col
          md="12"
          class="footer-sidebar"
        >
          <div class="d-flex">
            <b-button
              class="btn-cancel btn-footer"
              @click="closeModal()"
            >
              {{ $t('RefuseStatus.Cancel') }}
            </b-button>
            <b-button
              class="btn-save btn-footer"
              @click="addDepartments()"
            >
              {{ $t('RefuseStatus.Save') }}
            </b-button>
          </div>
        </b-col>
      </template>
    </b-sidebar>
    <modal-add-roles />
  </div>
</template>

<script>
  import {
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BRow,
    BCol,
    BSidebar
  } from 'bootstrap-vue';
  import { mapState, mapMutations } from 'vuex';
  import ModalAddRoles from './ModalAddRoles.vue';
  import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';

  export default {
    components: {
      BFormGroup,
      BFormInput,
      BFormTextarea,
      BButton,
      BRow,
      BCol,
      BSidebar,
      AddRole: () => import('@core/assets/icons/add-circle.svg'),
      CloseIcon: () => import('@core/assets/icons/close-icon.svg'),
      SingleSelect: () => import('@core/components/multiselect/SingleSelect.vue'),
      ConfirmIcon: () => import('@core/assets/icons/confirm-icon.svg'),
      RemoveRole: () => import('@core/assets/icons/red-line-icon.svg'),
      EditRole: () => import('@core/assets/icons/edit-role-icon.svg'),
      DeclineIcon: () => import('@core/assets/icons/decline-icon.svg'),
      ModalAddRoles
    },
    props: {
      currentId: {
        type: Number,
        default: null
      },
      modalTitle: {
        type: String,
        default: ''
      },
      responsibleList: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        forceRenderAddedList: true,
        isOpen: false,
        nameInput: '',
        description: '',
        hasError: false,
        errorMessage: '',
        currentRoleName: '',
        startName: '',
        responsible: [],
        prefix: localStorage.getItem('prefix'),
        flagNameEqual: false,
        states: {
          name: null,
          description: null,
          responsible: null
        },
        isRoleModalOpen: false
      };
    },
    mounted() {
      this.UPDATE_MODAL_ADD_EDIT_TOGGLE(false);
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      ...mapMutations('departments', [
        'UPDATE_MODAL_ADD_EDIT_TOGGLE',
        'UPDATE_RESPONSIBLE_LIST',
        'UPDATE_MODAL_ADD_ROLE_TOGGLE',
        'UPDATE_ADDED_ROLES',
        'UPDATE_ROLES_LIST'
      ]),
      activeCarousel() {
        const vm = this;
        $(function () {
          document.getElementsByClassName('chip-responsible')[0].setAttribute('tabIndex', '-1');

          const slider = document.querySelector('.multiselect__tags');
          let mouseDown = false;
          let startX, scrollLeft;

          let startDragging = function (e) {
            mouseDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
          };
          let stopDragging = function () {
            mouseDown = false;
          };

          slider.addEventListener('mousemove', (e) => {
            e.preventDefault();
            if (!mouseDown) {
              return;
            }
            const x = e.pageX - slider.offsetLeft;
            const scroll = x - startX;
            slider.scrollLeft = scrollLeft - scroll;
          });

          slider.addEventListener('mousedown', startDragging, false);
          slider.addEventListener('mouseup', stopDragging, false);
          slider.addEventListener('mouseleave', stopDragging, false);

          $('.multiselect__tags').blur((event) => {
            let input = vm.$refs.multiSelectResponsible;
            input.addTag(event.target.value);
          });
        });
      },
      forceRenderList() {
        this.forceRenderAddedList = false;
        this.$nextTick().then(() => {
          this.forceRenderAddedList = true;
        });
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      updateRoleName(id, bool) {
        if (this.currentRoleName == '') {
          this.hasError = true;
          this.errorMessage = this.$t('RequiredField');
          return false;
        }
        if (bool) {
          const payload = {
            name: this.currentRoleName
          };
          this.UPDATE_FLAG_SHOW_OVERLAY(true);
          this.$http
            .put(`/api/${this.prefix}/myconfig/function/${id}`, payload)
            .then((response) => {
              let newArr = this.rolesList.map((e) => {
                if (e.id == id) {
                  return {
                    ...e,
                    isEditting: false,
                    name: this.currentRoleName
                  };
                } else {
                  return e;
                }
              });
              this.clearValidations();
              this.UPDATE_ROLES_LIST(newArr);
            })
            .catch((error) => {
              error.response.data.errors.map((e) => {
                if (e.code === 3) {
                  this.hasError = true;
                  this.errorMessage = this.$t('DepartmentPage.RoleNameAlreadyExist');
                } else {
                  this.showToast(
                    this.$t('Error'),
                    'FrownIcon',
                    error.response.data.message,
                    'danger'
                  );
                }
              });
            })
            .finally(() => {
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
            });
        }
      },
      getInfoEdit(departments) {
        if (this.modalTitle.includes('Edit')) {
          departments.map((e) => {
            if (e.id === this.currentId) {
              this.nameInput = e.name;
              this.description = e.description;
              this.responsible = e.responsibleUsers.map((item) => ({
                value: item,
                text: this.responsibleList.find((f) => f.value === item)?.text
              }));
              this.UPDATE_ADDED_ROLES(
                this.rolesList.filter((obj) => {
                  return e.functions.includes(obj.id.toString());
                })
              );
            }
            if (e.child.length > 0) {
              this.getInfoEdit(e.child);
            }
          });
        }
      },
      stopEditting() {
        this.addedRoles.map((e) => {
          e['isEditting'] = false;
          this.forceRenderList();
        });
        this.clearValidations();
      },
      onEditRole(id) {
        this.addedRoles.map((e) => {
          e['isEditting'] = false;
          if (e.id === id) {
            e['isEditting'] = true;
            this.startName = e.name;
          }
        });
        this.forceRenderList();
      },
      onRemoveRole(id) {
        const updatedList = this.addedRoles.filter((e) => e.id !== id);
        this.UPDATE_ADDED_ROLES(updatedList);
      },
      closeModal() {
        this.UPDATE_MODAL_ADD_ROLE_TOGGLE(false);
        this.UPDATE_ADDED_ROLES([]);
        this.clearFields();
        this.$emit('closeModal');
      },
      fieldsValidate() {
        this.clearValidations();
        const validName = (this.states.name =
          this.nameInput && this.flagNameEqual === false ? true : false);
        const validDescription = (this.states.description = this.description != '');
        const validResponsible = (this.states.responsible = this.responsible.length > 0);

        return validName && validDescription && validResponsible;
      },
      clearFields() {
        this.nameInput = '';
        this.description = '';
        this.responsible = [];
      },
      clearValidations() {
        this.states.name = null;
        this.states.description = null;
        this.states.responsible = null;
        this.hasError = false;
        this.errorMessage = '';
        this.currentRoleName = '';
        this.addedRoles.map((e) => {
          e['isEditting'] = false;
        });
      },
      verifyTypeOfResource() {
        return this.modalTitle.includes('New') || this.modalTitle.includes('Novo') ? 'new' : 'edit';
      },
      async addDepartments() {
        this.flagNameEqual = false;
        const isValid = this.fieldsValidate();
        if (!isValid) return;

        this.UPDATE_FLAG_SHOW_OVERLAY(true);

        let typeOfResource = '';
        let toastText = '';

        const payload = {
          department_parent_id: this.currentId,
          name: this.nameInput,
          description: this.description,
          functions: this.addedRoles.map((e) => e.id),
          responsibleUsers: this.responsible.map((e) => e.value)
        };

        const payloadToEdit = {
          name: this.nameInput,
          description: this.description,
          functions: this.addedRoles.map((e) => e.id),
          responsibleUsers: this.responsible.map((e) => e.value)
        };

        typeOfResource = this.verifyTypeOfResource();

        if (typeOfResource === 'new') {
          toastText =
            this.modalTitle.includes('Department') || this.modalTitle.includes('Departamento')
              ? this.$t('DepartmentPage.DepartmentCreated')
              : this.$t('DepartmentPage.SubdepartmentCreated');
          await this.$http
            .post(`/api/${this.prefix}/myconfig/department`, payload)
            .then((response) => {
              this.showToast(this.$t('IntegrationPage.success'), 'SmileIcon', toastText, 'success');
              this.UPDATE_MODAL_ADD_EDIT_TOGGLE(false);
              this.clearValidations();
              this.$emit('addedItem');
            })
            .catch((error) => {
              error.response.data.errors.map((e) => {
                if (e.code === 3) {
                  this.flagNameEqual = true;
                } else {
                  this.showToast(
                    this.$t('Error'),
                    'FrownIcon',
                    error.response.data.message,
                    'danger'
                  );
                }
              });
              this.fieldsValidate();
            })
            .finally(() => {
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
            });
        } else if (typeOfResource === 'edit') {
          toastText = this.$t('ResourcePage.SavedChanges');
          await this.$http
            .put(`/api/${this.prefix}/myconfig/department/${this.currentId}`, payloadToEdit)
            .then((response) => {
              this.showToast(this.$t('IntegrationPage.success'), 'SmileIcon', toastText, 'success');
              this.UPDATE_MODAL_ADD_EDIT_TOGGLE(false);
              this.clearValidations();
              this.$emit('addedItem');
            })
            .catch((error) => {
              error.response.data.errors.map((e) => {
                if (e.code === 3) {
                  this.flagNameEqual = true;
                } else {
                  this.showToast(
                    this.$t('Error'),
                    'FrownIcon',
                    error.response.data.message,
                    'danger'
                  );
                }
              });
              this.fieldsValidate();
            })
            .finally(() => {
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
            });
        }
      },
      openRoles() {
        this.stopEditting();
        this.UPDATE_MODAL_ADD_ROLE_TOGGLE(true);
      }
    },
    computed: {
      ...mapState('departments', {
        modalAddEdit: 'modalAddEdit',
        isModalAddEditOpen: 'isModalAddEditOpen',
        isModalAddRoleOpen: 'isModalAddRoleOpen',
        dragList: 'dragList',
        rolesList: 'rolesList',
        addedRoles: 'addedRoles'
      })
    },
    watch: {
      isModalAddEditOpen(v) {
        this.isOpen = v;
        if (v) {
          this.getInfoEdit(this.dragList);
          this.activeCarousel();
        } else {
          this.UPDATE_MODAL_ADD_ROLE_TOGGLE(false);
          this.$emit('clearEditInfos');
          this.clearFields();
          this.clearValidations();
        }
      },
      isModalAddRoleOpen(v) {
        this.isRoleModalOpen = v;
      },
      rolesList(v) {
        const idsAdded = this.addedRoles.map((e) => e.id);
        this.UPDATE_ADDED_ROLES(v.filter((e) => idsAdded.includes(e.id)));
      }
    }
  };
</script>
<style lang="scss">
  .sidebar-add-roles {
    &.--role-openned {
      .b-sidebar.b-sidebar-right {
        right: 407px;
        transition: all 0.5s ease-out;
        @media (max-width: 814px) {
          left: 0;
          width: 50vw !important;
        }
      }
    }
    &.--role-closed {
      .b-sidebar.b-sidebar-right {
        right: 0;
        transition: all 0.5s ease-out;
      }
    }

    .custom-select {
      cursor: pointer;
      padding: 4px 34px 4px 8px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%23CFC4BE' stroke='%23CFC4BE' stroke-width='0.5'/%3E%3C/svg%3E") !important;
      &[disabled] {
        border-radius: 5px;
        border: 1px solid #7e7570;
        background-color: #eee;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%237E7570' stroke='%237E7570' stroke-width='0.5'/%3E%3C/svg%3E") !important;
        color: #7e7570 !important;
      }
    }

    .b-sidebar-header {
      display: contents;

      .header-sidebar {
        padding: 16px;

        .sidebar-title {
          display: flex;
          align-items: center;
          color: #4c4541;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
        }
      }

      .close-icon {
        border: 1px solid #974900;
        border-radius: 5px;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          width: 12px;
          height: 12px;
          path {
            fill: #974900;
          }
        }

        &:hover {
          background-color: #ffede2 !important;
        }

        &:active {
          background-color: #ffdbc4 !important;
        }
      }

      .separator {
        width: 100%;
        height: 1px;
        background: #cfc4be;
      }
    }

    .b-sidebar {
      height: 100% !important;
      .b-sidebar-body {
        padding: 16px 0;
        .form-group {
          .form-control.is-invalid,
          .form-control.is-valid {
            background-image: none !important;
          }

          .single-select {
            &.chip-responsible {
              display: flex;
              flex-direction: column;
              justify-content: center;
              width: 100% !important;
              border: none;

              .multiselect-invalid-feedback {
                margin-top: 3.5px;
              }

              .multiselect-component-single {
                .multiselect__tags {
                  width: 100% !important;
                  height: 100% !important;
                  white-space: nowrap !important;
                  display: flex !important;
                  align-items: center !important;
                  overflow-x: scroll;
                  overflow-y: hidden;
                  margin-top: 0;
                  height: 100% !important;
                  cursor: grabbing;
                  cursor: -webkit-grabbing;

                  .multiselect__input {
                    margin-bottom: 10px;
                  }

                  .multiselect__placeholder {
                    padding-bottom: 8px;
                    color: #cfc4be;
                  }

                  .multiselect__tag {
                    background-color: #974900;
                    margin-right: 0.6rem;
                    font-size: 13px;

                    .multiselect__tag-icon {
                      line-height: 21px;

                      &:hover {
                        background-color: #974900;
                        text-decoration: none;
                        opacity: 0.75;

                        &:after {
                          color: #000;
                        }
                      }

                      &:after {
                        color: #fff;
                      }
                    }
                  }
                  &::-webkit-scrollbar {
                    display: none !important;
                  }
                }

                .multiselect__select {
                  height: 36px;
                  background-color: #fff;
                  z-index: 1;
                }
              }

              &.focus {
                box-shadow: none;
              }
            }
          }
          &.roles-form {
            display: flex;
            flex-direction: column;
            gap: 8px;
            .title {
              color: #4c4541;
              font-size: 16px;
              font-weight: 600;
              line-height: 26px;
            }

            .roles-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 16px;
              border-radius: 6px;
              border: 1px solid #eee;
              background: #fafafa;
              margin-top: 8px;

              &.has-items {
                padding: 0;
                margin: 0;
                border: none;
                align-items: flex-start;
                gap: 8px;
                background: #fff;
              }

              .added-block {
                width: 100%;
                .added-roles {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  position: relative;
                  gap: 8px;
                  width: 100%;
                  padding: 8px 16px;
                  border-bottom: 1px solid #eeeeee;
                  .role-name {
                    white-space: nowrap;
                    word-break: break-all;
                    overflow: hidden;
                    color: #4c4541;
                    text-overflow: ellipsis;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    width: -webkit-fill-available;
                    padding: 4px 0 4px 16px;

                    .invalid-input {
                      position: absolute;
                      font-size: 10px;
                      bottom: 0px;
                      color: #ea5455;
                    }

                    .role-edit-input,
                    .form-control[readonly] {
                      width: -webkit-fill-available;
                      white-space: nowrap;
                      word-break: break-all;
                      overflow: hidden;
                      color: #4c4541;
                      text-overflow: ellipsis;
                      font-size: 14px;
                      font-weight: 600;
                      padding: 0;
                      max-height: 20px;
                      border: none;
                      background: transparent;
                      box-shadow: none;
                    }
                  }
                  .icons {
                    display: none;
                    .icon {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                      width: 28px;
                      height: 28px;
                      border-radius: 5px;

                      &.add,
                      &.edit {
                        svg {
                          fill: #974900;
                        }
                        &:hover {
                          background: #ffede2;
                        }
                        &:active {
                          background: #ffdbc4;
                        }
                      }

                      &.delete {
                        svg {
                          fill: #d32f2f;
                        }
                        &:hover {
                          background: #ffcdd2;
                        }
                        &:active {
                          background: #ef9a9a;
                        }
                      }
                    }
                  }
                  &:hover {
                    border-color: #974900;
                    background: #ffede2;
                    .icons {
                      display: flex;
                      gap: 8px;
                    }
                    &:has(.role-edit-input) {
                      border-color: #eeeeee;
                      background: #fff;
                    }
                  }
                }
              }

              .no-roles {
                color: #4c4541;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                padding-bottom: 16px;
              }
              .add-role {
                display: flex;
                align-items: center;
                width: fit-content;
                padding: 2px 14px;
                gap: 6px;
                border-radius: 5px;
                color: #974900;
                font-size: 12px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.15px;
                cursor: pointer;

                &:hover {
                  background-color: #ffede2 !important;
                }

                &:active {
                  background-color: #ffdbc4 !important;
                }
              }
            }
          }
          .form-control {
            &::placeholder {
              color: #cfc4be;
            }
            &:focus {
              border-color: #974900;
            }
          }
        }
        &::-webkit-scrollbar {
          display: none !important;
        }
      }
    }
    .footer-sidebar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 16px;

      .btn-footer {
        box-shadow: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        border-radius: 5px;

        &:hover {
          box-shadow: none;
        }

        &.btn-reset {
          background-color: transparent !important;
          color: #974900 !important;
          font-size: 12px !important;
          border: 1px solid transparent !important;
          padding: 4px 14px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-cancel {
          background-color: transparent !important;
          color: #974900 !important;
          border: 1px solid #974900 !important;
          margin-right: 8px;
          padding: 8px 21px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-save {
          border: 1px solid #974900 !important;
          background-color: #974900 !important;
          color: #ffff !important;
          padding: 8px 21px;

          &:hover {
            background-color: #9f5714 !important;
          }

          &:active {
            background-color: #a45f1f !important;
          }
        }
      }
    }
  }
</style>
