<template>
  <div>
    <b-sidebar
      id="roles-sidebar-right"
      class="sidebar-roles"
      right
      bg-variant="white"
      width="407px"
      v-model="isRoleOpen"
      @hidden="closeModal()"
    >
      <template #header>
        <div class="header-sidebar d-flex justify-content-between">
          <span class="sidebar-title"> </span>

          <span
            class="close-icon"
            @click="closeModal()"
          >
            <CloseIcon />
          </span>
        </div>
      </template>
      <template>
        <div class="roles-modal">
          <div class="text-block">
            <span class="title">{{ $t('DepartmentPage.AddRole') }}</span>
            <span class="subtitle">{{ $t('DepartmentPage.RoleSubtext') }}</span>
          </div>
          <div class="search-create">
            <div class="input">
              <b-form-group
                :invalid-feedback="
                  flagRoleNameEqual
                    ? $t('DepartmentPage.RoleNameAlreadyExist')
                    : $t('RequiredField')
                "
              >
                <b-form-input
                  id="roleName"
                  v-model="roleName"
                  :placeholder="$t('DepartmentPage.NewRole')"
                  :state="states.roleName"
                />
              </b-form-group>
            </div>
            <b-button
              class="btn-create"
              @click="onCreate()"
              >{{ $t('DepartmentPage.Create') }}</b-button
            >
          </div>
        </div>
        <div
          class="block-role"
          v-if="forceRenderList"
        >
          <div
            v-for="role in rolesList"
            class="added-roles"
            :key="role.id"
          >
            <div class="role-name">
              <b-form-input
                :id="`editRoleName-${role.id}`"
                :class="role.isEditting ? 'role-edit-input' : ''"
                :ref="`role-input-${role.id}`"
                :value="role.name"
                :state="states.roleName"
                :readonly="!role.isEditting"
                @input="
                  (value) => {
                    currentRoleName = value;
                    updateRoleName(role.id, false);
                  }
                "
              />
              <span
                v-if="hasError && role.isEditting"
                class="invalid-input"
                >{{ errorMessage }}</span
              >
            </div>
            <div
              v-if="!role.isEditting"
              class="icons"
            >
              <div
                class="icon add"
                @click="onAddRole(role.id)"
              >
                <AddRole />
              </div>
              <div
                class="icon edit"
                @click="onEditRole(role.id)"
              >
                <EditRole />
              </div>
              <div
                class="icon delete"
                @click="onDeleteRole(role.id)"
              >
                <DeleteRole />
              </div>
            </div>
            <div
              v-else
              class="icons"
            >
              <div
                class="icon add"
                @click="updateRoleName(role.id, true)"
              >
                <ConfirmIcon />
              </div>
              <div
                class="icon delete"
                @click="stopEditting()"
              >
                <DeclineIcon />
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import { BFormGroup, BFormInput, BSidebar, BButton } from 'bootstrap-vue';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

  export default {
    components: {
      BFormGroup,
      BFormInput,
      BSidebar,
      BButton,
      ToastificationContent,
      CloseIcon: () => import('@core/assets/icons/close-icon.svg'),
      AddRole: () => import('@core/assets/icons/add-circle.svg'),
      EditRole: () => import('@core/assets/icons/edit-role-icon.svg'),
      DeleteRole: () => import('@core/assets/icons/trash-icon.svg'),
      ConfirmIcon: () => import('@core/assets/icons/confirm-icon.svg'),
      DeclineIcon: () => import('@core/assets/icons/decline-icon.svg')
    },
    data() {
      return {
        forceRenderList: true,
        isRoleOpen: false,
        roleName: '',
        currentRoleName: '',
        startName: '',
        flagRoleNameEqual: false,
        hasError: false,
        errorMessage: '',
        prefix: localStorage.getItem('prefix'),
        states: {
          roleName: null
        }
      };
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      ...mapMutations('departments', [
        'UPDATE_MODAL_ADD_ROLE_TOGGLE',
        'UPDATE_ADDED_ROLES',
        'UPDATE_ROLES_LIST'
      ]),
      forceRenderRolesList() {
        this.forceRenderList = false;
        this.$nextTick().then(() => {
          this.forceRenderList = true;
        });
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      closeModal() {
        this.UPDATE_MODAL_ADD_ROLE_TOGGLE(false);
      },
      fieldsValidate() {
        this.clearValidations();
        const validName = (this.states.roleName =
          this.roleName && !this.flagRoleNameEqual ? true : false);
        return validName;
      },
      clearValidations() {
        this.states.roleName = null;
        this.hasError = false;
        this.errorMessage = '';
        this.currentRoleName = '';
        this.rolesList.map((e) => {
          e['isEditting'] = false;
        });
      },
      updateRoleName(id, bool) {
        if (this.currentRoleName == '') {
          this.hasError = true;
          this.errorMessage = this.$t('RequiredField');
          return false;
        }
        if (bool) {
          const payload = {
            name: this.currentRoleName
          };
          this.UPDATE_FLAG_SHOW_OVERLAY(true);
          this.$http
            .put(`/api/${this.prefix}/myconfig/function/${id}`, payload)
            .then((response) => {
              let newArr = this.rolesList.map((e) => {
                if (e.id == id) {
                  return {
                    ...e,
                    isEditting: false,
                    name: this.currentRoleName
                  };
                } else {
                  return e;
                }
              });
              this.clearValidations();
              this.UPDATE_ROLES_LIST(newArr);
            })
            .catch((error) => {
              error.response.data.errors.map((e) => {
                if (e.code === 3) {
                  this.hasError = true;
                  this.errorMessage = this.$t('DepartmentPage.RoleNameAlreadyExist');
                } else {
                  this.showToast(
                    this.$t('Error'),
                    'FrownIcon',
                    error.response.data.message,
                    'danger'
                  );
                }
              });
            })
            .finally(() => {
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
            });
        }
      },
      onCreate() {
        this.flagRoleNameEqual = false;
        const isValid = this.fieldsValidate();
        if (!isValid) return;

        this.UPDATE_FLAG_SHOW_OVERLAY(true);

        const payload = {
          name: this.roleName
        };

        this.$http
          .post(`/api/${this.prefix}/myconfig/function`, payload)
          .then((response) => {
            this.roleName = '';
            let newArr = this.rolesList;
            newArr.push(response.data.data);
            this.clearValidations();
            this.UPDATE_ROLES_LIST(newArr);
          })
          .catch((error) => {
            error.response.data.errors.map((e) => {
              if (e.code === 3) {
                this.flagRoleNameEqual = true;
              } else {
                this.showToast(
                  this.$t('Error'),
                  'FrownIcon',
                  error.response.data.message,
                  'danger'
                );
              }
            });
            this.fieldsValidate();
          })
          .finally(() => {
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      },
      onAddRole(id) {
        const roleToAdd = this.rolesList.filter((e) => e.id === id)[0];
        const roleToRemove = this.addedRoles.find((role) => role.id === roleToAdd.id);

        if (roleToRemove) {
          const removeDuplicate = this.addedRoles.filter((role) => role.id !== roleToAdd.id);
          this.UPDATE_ADDED_ROLES(removeDuplicate);
        }
        this.addedRoles.push(roleToAdd);
        this.UPDATE_ADDED_ROLES(this.addedRoles);
      },
      onEditRole(id) {
        this.rolesList.map((e) => {
          e['isEditting'] = false;
          if (e.id === id) {
            e['isEditting'] = true;
            this.startName = e.name;
          }
        });
        this.forceRenderRolesList();
      },
      stopEditting() {
        this.rolesList.map((e) => {
          e['isEditting'] = false;
          this.forceRenderRolesList();
        });
        this.clearValidations();
      },
      onDeleteRole(id) {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        this.$http
          .delete(`/api/${this.prefix}/myconfig/function/${id}`)
          .then((response) => {
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
            this.UPDATE_ROLES_LIST(this.rolesList.filter((e) => e.id !== id));
            this.showToast(
              this.$t('IntegrationPage.success'),
              'SmileIcon',
              this.$t('DepartmentPage.DeletedItems'),
              'success'
            );
          })
          .catch((error) => {
            error.response.data.errors.map((e) => {
              this.showToast(this.$t('Error'), 'FrownIcon', error.response.data.message, 'danger');
            });
            this.fieldsValidate();
          })
          .finally(() => {
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      }
    },
    computed: {
      ...mapState('departments', {
        isModalAddRoleOpen: 'isModalAddRoleOpen',
        addedRoles: 'addedRoles',
        rolesList: 'rolesList'
      })
    },
    watch: {
      isModalAddRoleOpen(v) {
        this.stopEditting();
        this.isRoleOpen = v;
        this.clearValidations();
      },
      rolesList(v) {
        this.UPDATE_ROLES_LIST(v);
      }
    }
  };
</script>
<style lang="scss">
  .sidebar-roles {
    .custom-select {
      cursor: pointer;
      padding: 4px 34px 4px 8px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%23CFC4BE' stroke='%23CFC4BE' stroke-width='0.5'/%3E%3C/svg%3E") !important;
      &[disabled] {
        border-radius: 5px;
        border: 1px solid #7e7570;
        background-color: #eee;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%237E7570' stroke='%237E7570' stroke-width='0.5'/%3E%3C/svg%3E") !important;
        color: #7e7570 !important;
      }
    }

    .b-sidebar.b-sidebar-right {
      transition: all 0.5s ease-out;
      border-left: 1px solid #cfc4be;
      @media (max-width: 814px) {
        width: 50vw !important;
      }
    }

    .b-sidebar-header {
      display: contents;

      .header-sidebar {
        padding: 16px;
      }

      .close-icon {
        border: 1px solid #974900;
        border-radius: 5px;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          width: 12px;
          height: 12px;
          path {
            fill: #974900;
          }
        }

        &:hover {
          background-color: #ffede2 !important;
        }

        &:active {
          background-color: #ffdbc4 !important;
        }
      }
    }

    .b-sidebar {
      height: 100% !important;
      .b-sidebar-body {
        padding: 16px 0;
        .roles-modal {
          padding: 0 16px 8px;

          .text-block {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding-bottom: 16px;

            .title {
              color: #4c4541;
              font-size: 16px;
              font-weight: 600;
              line-height: 26px;
            }

            .subtitle {
              color: #998f8a;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
            }
          }
        }

        .block-role {
          overflow-y: auto;
          max-height: 80%;

          &::-webkit-scrollbar {
            width: 7px;
          }

          &::-webkit-scrollbar-track {
            background: #fff;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #cfc4be;
            border-radius: 9px;
          }

          .added-roles {
            display: flex;
            justify-content: space-between;
            position: relative;
            gap: 8px;
            padding: 8px 16px;
            border-bottom: 1px solid #eeeeee;
            .role-name {
              width: -webkit-fill-available;
              padding: 4px 0 4px 16px;

              .invalid-input {
                position: absolute;
                font-size: 10px;
                bottom: 0px;
                color: #ea5455;
              }

              .role-edit-input,
              .form-control[readonly] {
                width: -webkit-fill-available;
                white-space: nowrap;
                word-break: break-all;
                overflow: hidden;
                color: #4c4541;
                text-overflow: ellipsis;
                font-size: 14px;
                font-weight: 600;
                padding: 0;
                max-height: 20px;
                border: none;
                background: transparent;
                box-shadow: none;
              }
            }
            .icons {
              display: none;
              .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                width: 28px;
                height: 28px;
                border-radius: 5px;

                &.add,
                &.edit {
                  svg {
                    fill: #974900;
                  }
                  &:hover {
                    background: #ffede2;
                  }
                  &:active {
                    background: #ffdbc4;
                  }
                }

                &.delete {
                  svg {
                    fill: #d32f2f;
                  }
                  &:hover {
                    background: #ffcdd2;
                  }
                  &:active {
                    background: #ef9a9a;
                  }
                }
              }
            }
            &:hover {
              border-color: #974900;
              background: #ffede2;
              .icons {
                display: flex;
                gap: 8px;
              }
              &:has(.role-edit-input) {
                border-color: #eeeeee;
                background: #fff;
              }
            }
          }
        }

        .search-create {
          display: flex;
          gap: 8px;
          width: 100%;
          .input {
            width: inherit;
            .form-group {
              margin: 0 !important;
              .form-control.is-invalid,
              .form-control.is-valid {
                background-image: none !important;
              }

              .form-control {
                &::placeholder {
                  color: #cfc4be;
                }
                &:focus {
                  border-color: #974900;
                }
              }
            }
            &::-webkit-scrollbar {
              display: none !important;
            }
          }

          .btn-create {
            max-height: 38px;
            background-color: #974900 !important;
            padding: 6px 21px;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.175px;
          }
        }
      }
    }
  }
</style>
